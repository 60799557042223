import React, { useState } from 'react';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Outlet } from 'react-router';
import { IfUserHasRole, RequiresUserRole } from '../authentication/UserRoles';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { BackButton } from '../../infrastructure/interface/components/BackButton';
import styled from 'styled-components/macro';
import { spacing24 } from '../../styling/design/spacing';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import {
  InvestorDetailsContextProvider,
  useInvestorDetailsContext,
} from './InvestorDetailsContext';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { useInvestorIdUrlParam } from './useInvestorIdUrlParam';
import { useNavigate } from 'react-router-dom';
import { TransactionsFilterState } from '../transactions/types';
import queryString from 'query-string';
import {
  InvestorDetailsCounterpartDropdownOption,
  useGetInvestorDetailsCounterpartDropdownOptionsRequest,
} from './GetInvestorDetailsCounterpartDropdownOptions';
import { InvestorDetailsCounterpartDropdown } from './InvestorDetailsCounterpartDropdown';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';

export const InvestorDetailsLayout = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.investorDetailsPage.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <InvestorDetailsContextProvider>
        <InvestorDetailsLayoutApiRequestStateWrapper />
      </InvestorDetailsContextProvider>
    </RequiresUserRole>
  );
};

const InvestorDetailsLayoutApiRequestStateWrapper = () => {
  const { translate } = useInternationalisation();
  const { innerComponentApiError, setInnerComponentApiError } = useInvestorDetailsContext();

  const investorId = useInvestorIdUrlParam();
  const [investorIdIsInvalid, setInvestorIdIsInvalid] = useState(false);

  const counterpartDropdownOptionsRequest =
    useGetInvestorDetailsCounterpartDropdownOptionsRequest();

  const makeRequest = () => {
    setInnerComponentApiError(null);
    counterpartDropdownOptionsRequest.makeRequest({
      queryParameters: {
        selectedCounterpartId: investorId,
        includeBeneficialOwners: true,
      },
      onSuccess: ({ options }) => {
        if (!options.some((option) => option.counterpartId === investorId)) {
          setInvestorIdIsInvalid(true);
        }
      },
    });
  };

  useOnMount(() => makeRequest());

  if (innerComponentApiError != null) {
    return <RequestFailedPage error={innerComponentApiError} retry={makeRequest} />;
  }

  if (investorIdIsInvalid) {
    return (
      <RequestFailedPage
        error={translate('pages.investorDetailsPage.errorMessages.permissionDenied')}
      />
    );
  }

  return (
    <ApiRequestStateWrapper
      apiRequestState={counterpartDropdownOptionsRequest.state}
      retry={makeRequest}
    >
      {(response) => (
        <InvestorDetailsLayoutComponent counterpartDropdownOptions={response.options} />
      )}
    </ApiRequestStateWrapper>
  );
};

type InvestorDetailsLayoutComponentProps = {
  counterpartDropdownOptions: Array<InvestorDetailsCounterpartDropdownOption>;
};

const InvestorDetailsLayoutComponent = (props: InvestorDetailsLayoutComponentProps) => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();
  const navigate = useNavigate();

  const onInvestorIdChange = (newInvestorId: number | null) =>
    navigate(`/investor-details/${newInvestorId ?? ''}`);

  const transactionsLinkQueryParams: Partial<TransactionsFilterState> = {
    investorId: investorId,
    endDate: null,
    startDate: null,
  };

  return (
    <PaddedPage>
      <InvestorDetailsCounterpartDropdown
        options={props.counterpartDropdownOptions}
        onChange={onInvestorIdChange}
        value={investorId}
      />
      <NavigationActionsContainer>
        <BackButton />
        <DesktopOnly>
          <IfUserHasRole userRole="Manager">
            <AppLink to={`/transactions?${queryString.stringify(transactionsLinkQueryParams)}`}>
              {translate('pages.investorDetailsPage.viewTransactions')}
            </AppLink>
          </IfUserHasRole>
        </DesktopOnly>
      </NavigationActionsContainer>
      <Outlet />
    </PaddedPage>
  );
};

const NavigationActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing24} 0;
`;
