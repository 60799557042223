import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { InvestorSelectField } from '../../metadata/investor/InvestorSelectField';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { HasUserRole, IfUserHasRole } from '../../authentication/UserRoles';
import { CompanySelectForTransactionsField } from '../../metadata/company/forTransactions/CompanySelectForTransactionsField';
import { companySelectForTransactionsContextCode } from '../../metadata/company/forTransactions/CompanySelectForTransactionsSettings';
import { TransactionTypeSelectField } from '../../metadata/transactions/TransactionTypeSelectField';
import {
  defaultTransactionTypeSelectSettings,
  transactionTypeSelectOptionCode,
} from '../../metadata/transactions/TransactionTypeSelectBase';
import { allCompaniesCompanyId, allCounterpartsCounterpartId } from '../../metadata/idConstants';
import { DealingDateSelectField } from '../../metadata/transactions/dealingDate/DealingDateSelectField';
import {
  GetTransactionEquityAttributionDropdownOptionsResponse,
  TransactionEquityAttributionDropdownOption,
} from '../../metadata/equityAttribution/forTransactions/GetTransactionEquityAttributionDropdownOptionsResponse';
import styled from 'styled-components/macro';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { TextAreaField } from '../../../infrastructure/forms/fields/TextAreaField';
import { Spinner } from '../../../infrastructure/interface/components/Spinner';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { useDevice } from '../../../infrastructure/hooks/useDevice';
import { placeTransactionInvestorFieldUserRoles } from './placeTransactionUserRoles';
import { initialPlaceTransactionFormValues, PlaceTransactionFormModel } from './PlaceTransaction';
import { PlaceTransactionShareClassSeriesField } from './fields/PlaceTransactionShareClassSeriesField';
import { PlaceTransactionShareClassSeriesSubFields } from './fields/PlaceTransactionShareClassSeriesSubFields';
import { PlaceTransactionSwitchToFundField } from './fields/PlaceTransactionSwitchToFundField';
import { PlaceTransactionSwitchToShareClassSeriesField } from './fields/PlaceTransactionSwitchToShareClassSeriesField';
import { PlaceTransactionAmountOrSharesField } from './fields/PlaceTransactionAmountOrSharesField';
import {
  CompanyEqualizationTypeCode,
  companyEqualizationTypes,
  companyTransactionTypes,
  useGetCompanyTransactionDetails,
} from './useGetCompanyTransacitonDetails';
import { isEmpty } from 'lodash';
import { dealingTransactionType } from '../../metadata/transactions/DealingTransactionType';
import { transactionLockCode } from '../../metadata/transactions/TransactionLock';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';

type Props = {
  onFormLoadError: (error: string) => void;
  allowWebInstructions: boolean;
  setCompanyEqualizationType: (value: CompanyEqualizationTypeCode) => void;
};

export const PlaceTransactionFormFields = ({
  onFormLoadError,
  allowWebInstructions,
  setCompanyEqualizationType,
}: Props) => {
  const { translate } = useInternationalisation();
  const { isDesktop } = useDevice();

  const [seriesDropdownResponse, setSeriesDropdownResponse] =
    useState<GetTransactionEquityAttributionDropdownOptionsResponse | null>(null);

  const {
    makeRequest: makeCompanyTransactionDetailsRequest,
    state: {
      inProgress: companyTransactionDetailsRequestInProgress,
      response: companyTransactionDetailsRequestResponse,
    },
  } = useGetCompanyTransactionDetails();
 
  const isAmountOnly=!(companyTransactionDetailsRequestResponse?.transactionTypes===companyTransactionTypes.all ||
    companyTransactionDetailsRequestResponse?.transactionTypes===companyTransactionTypes.allExceptSwitch);

  const {
    values: {
      investorId,
      companyId,
      transactionType,
      dealingDate,
      equityAttributionId,
      switchToCompanyId,
    },
    setValues: setFormValues,
    touched,
    setFieldValue,
    initialValues,
  } = useFormikContext<PlaceTransactionFormModel>();

  const investorIdIsValid = investorId != null && investorId !== allCounterpartsCounterpartId;
  const investorIdChangeFormValues: PlaceTransactionFormModel = {
    ...initialPlaceTransactionFormValues,
    investorId,
  };
  const onInvestorIdChange = (newInvestorId: number | null) => {
    if (!isEmpty(touched) || newInvestorId !== initialValues.investorId) {
      setFormValues(investorIdChangeFormValues);
    }
  };

  const companyIdIsValid = companyId != null && companyId !== allCompaniesCompanyId;
  const companyIdChangeFormValues: PlaceTransactionFormModel = {
    ...investorIdChangeFormValues,
    companyId,
  };
  const onCompanyIdChange = (newCompanyId: number | null) => {
    if (newCompanyId != null && newCompanyId !== allCompaniesCompanyId) {
      makeCompanyTransactionDetailsRequest({
        queryParameters: { companyId: newCompanyId },
        onFailure: (error) => onFormLoadError(error),
        onSuccess: (response) => {
          setCompanyEqualizationType(response.equalizationTypeCode);

          if (response.equalizationTypeCode === companyEqualizationTypes.limitedPartnership) {
            setFieldValue('equityAttributionId', null);

            if (transactionType === dealingTransactionType.FullRedemption) {
              setFieldValue('amountOrShares', null);
            }
          }
        },
      });
    }

    if (!isEmpty(touched) || newCompanyId !== initialValues.companyId) {
      setFormValues(companyIdChangeFormValues);
    }
  };

  const transactionTypeChangeFormValues: PlaceTransactionFormModel = {
    ...companyIdChangeFormValues,
    equityAttributionId,
    dealingDate,
    transactionType,
    transactionLock: transactionLockCode.Amount,
  };
  const onTransactionTypeChange = (newTransactionType: string | null) => {
    if (!isEmpty(touched) || newTransactionType !== initialValues.transactionType) {
      setFormValues(transactionTypeChangeFormValues);
    }
  };

  const dealingDateChangeFormValues: PlaceTransactionFormModel = {
    ...transactionTypeChangeFormValues,
    dealingDate,
  };
  const onDealingDateChange = (newDealingDate: IsoDatestamp | null) => {
    if (!isEmpty(touched) || newDealingDate !== initialValues.dealingDate) {
      setFormValues(dealingDateChangeFormValues);
    }
  };

  const equityAttributionIdIsValid =
    equityAttributionId != null ||
    companyTransactionDetailsRequestResponse?.equalizationTypeCode ===
      companyEqualizationTypes.limitedPartnership;
  const selectedEquityAttributionOption: TransactionEquityAttributionDropdownOption | null =
    seriesDropdownResponse?.options.find(
      (option) => option.equityAttributionId === equityAttributionId
    ) ?? null;

  const FieldsWrapper = isDesktop ? Panel : MobileContentContainer;

  return (
    <HasUserRole userRole="Investor">
      {(userIsInvestor) => (
        <FieldsWrapper>
          <IfUserHasRole userRole={placeTransactionInvestorFieldUserRoles}>
            <InvestorSelectField
              data-testid={placeTransactionInvestorSelectTestId}
              fieldName="investorId"
              label={translate('pages.placeTransaction.labels.investor')}
              onFieldValueChange={onInvestorIdChange}
              onError={onFormLoadError}
              settings={{ forTransacting: true }}
            />
          </IfUserHasRole>
          {(userIsInvestor || investorIdIsValid) && (
            <CompanySelectForTransactionsField
              data-testid={placeTransactionCompanySelectTestId}
              fieldName="companyId"
              label={translate('pages.placeTransaction.labels.fund')}
              onFieldValueChange={onCompanyIdChange}
              onError={onFormLoadError}
              settings={{
                investorId,
                context: companySelectForTransactionsContextCode.Individual,
              }}
            />
          )}
          {companyIdIsValid && (
            <>
              <TransactionTypeSelectField
                data-testid={placeTransactionTransactionTypeSelectTestId}
                fieldName="transactionType"
                label={translate('pages.placeTransaction.labels.transactionType')}
                onFieldValueChange={onTransactionTypeChange}
                onError={onFormLoadError}
                settings={{
                  ...defaultTransactionTypeSelectSettings,
                  optionCode: transactionTypeSelectOptionCode.Dealing,
                  companyId,
                }}
              />
              {transactionType != null && (
                <DealingDateSelectField
                  data-testid={placeTransactionDealingDateSelectTestId}
                  fieldName="dealingDate"
                  label={translate('pages.placeTransaction.labels.dealingDate')}
                  onFieldValueChange={onDealingDateChange}
                  onError={onFormLoadError}
                  settings={{ companyId, transactionType }}
                />
              )}
            </>
          )}
          {(userIsInvestor || investorIdIsValid) &&
            companyIdIsValid &&
            transactionType != null &&
            dealingDate != null &&
            (companyTransactionDetailsRequestInProgress ||
            companyTransactionDetailsRequestResponse == null ? (
              <Spinner size="medium" />
            ) : (
              <>
                <PlaceTransactionShareClassSeriesField
                  companyEqualisationType={
                    companyTransactionDetailsRequestResponse?.equalizationTypeCode
                  }
                  transactionType={transactionType}
                  settings={{ investorId, companyId, transactionType, dealingDate }}
                  onLoaded={setSeriesDropdownResponse}
                  onError={onFormLoadError}
                />
                {equityAttributionIdIsValid && (
                  <>
                    {equityAttributionId !== null && (
                      <>
                        <PlaceTransactionShareClassSeriesSubFields
                          transactionType={transactionType}
                          shareHolding={selectedEquityAttributionOption?.shareHolding}
                          shareHoldingDecimals={selectedEquityAttributionOption?.shareDecimals}
                          netAssetValue={selectedEquityAttributionOption?.netAssetValue}
                          netAssetValueDecimals={selectedEquityAttributionOption?.currencyDecimals}
                          netAssetValueCurrencyCode={selectedEquityAttributionOption?.currencyCode}
                        />
                        <PlaceTransactionSwitchToFundField
                          transactionType={transactionType}
                          onError={onFormLoadError}
                          investorId={investorId}
                        />
                        {switchToCompanyId != null && (
                          <PlaceTransactionSwitchToShareClassSeriesField
                            transactionType={transactionType}
                            settings={{
                              investorId,
                              companyId: switchToCompanyId,
                              transactionType,
                              dealingDate,
                              switchTo : true
                            }}
                            onError={onFormLoadError}
                          />
                        )}
                      </>
                    )}
                    <PlaceTransactionAmountOrSharesField
                      companyEqualizationType={
                        companyTransactionDetailsRequestResponse.equalizationTypeCode
                      }
                      transactionType={transactionType}
                      shareDecimals={companyTransactionDetailsRequestResponse.shareDecimals}
                      amountCurrencyCode={
                        selectedEquityAttributionOption == null
                          ? companyTransactionDetailsRequestResponse.currencyCode
                          : selectedEquityAttributionOption.currencyCode
                      }
                      isAmountOnly ={isAmountOnly}
                    />
                    {companyTransactionDetailsRequestResponse.canEnterTransactionReference && (
                      <InputField
                        fieldName="transactionReference"
                        label={translate('pages.placeTransaction.labels.yourReference')}
                      />
                    )}
                    {allowWebInstructions && (
                      <TextAreaField
                        fieldName="additionalInstructions"
                        label={translate('pages.placeTransaction.labels.additionalInstructions')}
                      />
                    )}
                  </>
                )}
              </>
            ))}
        </FieldsWrapper>
      )}
    </HasUserRole>
  );
};

const MobileContentContainer = styled.div``;

export const placeTransactionInvestorSelectTestId = 'place-transaction-investor-select';
export const placeTransactionCompanySelectTestId = 'place-transaction-company-select';
export const placeTransactionTransactionTypeSelectTestId =
  'place-transaction-transaction-type-select';
export const placeTransactionDealingDateSelectTestId = 'place-transaction-dealing-date-select';
